<template>
  <div class="unpublish-mp">
    <p class="deletion-warning">
      {{ $t('POS.MARKETPLACE.DELETION.UNPUBLISH_DESCRIPTION') }}
    </p>
    <p>
      {{ `${$t('POS.MARKETPLACE.DELETION.IDEA_COUNT')} ${$t(ideaCount)}` }}
    </p>
    <p v-if="earnings.amount">
      {{
        `${$t('POS.MARKETPLACE.DELETION.EARNINGS')} ${sprdCurrency(
          earnings.amount,
          earnings.currencyId,
        )}`
      }}
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import statisticsService from '@/api/statisticsService/statisticsService';
import { subYears } from 'date-fns';

export default {
  name: 'UnpublishFromMp',
  data() {
    return {
      earnings: {},
    };
  },
  async created() {
    const data = await statisticsService.getSalesOverview({
      pos: 'MP',
      dateFrom: subYears(new Date(), 1),
    });

    this.earnings = {
      amount: data?.earnings?.amount,
      currencyId: data?.currencyId,
    };
  },
  computed: {
    ...mapState({
      meta: (s) => s.ideas.meta,
    }),
    ideaCount() {
      return this.meta?.count || 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.unpublish-mp,
.deletion-warning {
  margin-bottom: 16px;
}

p {
  @extend .text-grey;
}
</style>
