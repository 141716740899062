<template>
  <a
    v-if="color"
    class="colorCircle"
    :class="{
      active: active && !deactivated,
      deactivated: deactivated,
      disabled: deactivated && !allowClickWhenDeactivated,
      'show-plus': showPlus && !active,
    }"
    @click="(!deactivated || allowClickWhenDeactivated) && $emit('onClick')"
  >
    <div
      v-tooltip="{ content: disableTooltip ? null : name }"
      class="color"
      :style="[backgroundStyle, { width: `${size}px`, height: `${size}px` }]"
      :class="{
        white: isBrightColor,
        multi: isMultiColor,
        transparent: transparent,
      }"
    >
      <div v-if="glossy" class="glossy-overlay"></div>
      <Icon
        class="active-icon"
        icon="accept"
        :style="{ width: `${size / 2}px`, height: `${size / 2}px` }"
      />
      <Icon
        class="deactivated-icon"
        icon="close"
        :style="{ width: `${size / 2}px`, height: `${size / 2}px` }"
      />
      <Icon
        class="plus-icon"
        icon="plus"
        :style="{ width: `${size / 2}px`, height: `${size / 2}px` }"
      />
    </div>
    <p v-if="label">{{ label }}</p>
  </a>
</template>

<script>
import assortmentHelper from '@/assortmentHelper/assortmentHelper';

export default {
  name: 'ColorCircle',
  props: {
    color: {
      required: true,
    },
    label: {
      required: false,
    },
    active: {
      required: false,
      default: false,
    },
    deactivated: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowClickWhenDeactivated: {
      required: false,
      default: false,
    },
    size: {
      required: false,
      default: 32,
    },
    name: {
      required: false,
    },
    disableTooltip: {
      required: false,
    },
    showPlus: {
      required: false,
      default: false,
    },
    transparent: {
      type: Boolean,
    },
    glossy: {
      type: Boolean,
    },
  },
  emits: ['onClick'],
  computed: {
    backgroundStyle() {
      if (Array.isArray(this.color)) {
        const firstColor = this.color[0].startsWith('#')
          ? this.color[0]
          : `#${this.color[0]}`;
        let secondColor = firstColor;

        if (this.color.length > 1) {
          secondColor = this.color[1].startsWith('#')
            ? this.color[1]
            : `#${this.color[1]}`;
          return {
            background: `linear-gradient(to right, ${firstColor} 0%, ${firstColor} 50%, ${secondColor} 51%, ${secondColor} 100%)`,
          };
        }

        return {
          backgroundColor: firstColor,
        };
      } else {
        return {
          backgroundColor: this.color.startsWith('#')
            ? this.color
            : `#${this.color}`,
        };
      }
    },
    isBrightColor() {
      if (Array.isArray(this.color) && this.color.length > 1) {
        return true;
      }
      if (this.color === '#Transparent') {
        return true;
      }
      return assortmentHelper.isBrightColor(
        Array.isArray(this.color) ? this.color[0] : this.color,
      );
    },
    isMultiColor() {
      return Array.isArray(this.color) && this.color.length > 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/styleguide';
@import 'src/scss/constants';
@import 'src/scss/utils';
@import 'src/scss/mixins';

.colorCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;

  p {
    margin: 0;
    max-width: 100%;
    @extend .text-sm;
    @extend .text-truncate;
  }
  .color {
    border-radius: 100%;
    border: 1px solid transparent;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px;

    .glossy-overlay {
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border-radius: 100%;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        top: -50%;
        right: -50%;
        bottom: -50%;
        left: -50%;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.95) 50%,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        );
        animation: glossyShine 2s forwards;
        animation-iteration-count: infinite;
        transform: rotateZ(60deg) translate(-5px, 5px);
      }
    }

    &.transparent {
      @include tiled-background-light;
      background-size: 10px;
    }

    @keyframes glossyShine {
      0% {
        transform: rotateZ(60deg) translate(-5px, 15px);
      }
      50% {
        transform: rotateZ(60deg) translate(1px, -24px);
      }
      100% {
        transform: rotateZ(60deg) translate(1px, -24px);
      }
    }

    .icon {
      display: none;
      color: $white;
      width: 16px;
      height: 16px;
    }

    &.white,
    &.transparent {
      border-color: $grey40;
      .icon {
        color: $grey80;
      }
    }

    &.multi {
      .icon {
        stroke: $pa-color-main;
        fill: $pa-color-main;
      }
    }
  }

  &.active {
    .color {
      .active-icon {
        display: block;
      }

      box-shadow: 0 0 0 2px $pa-color-main;
    }
  }

  &.deactivated {
    .color {
      .deactivated-icon {
        display: block;
      }
      opacity: 0.4;
    }
  }

  &.show-plus {
    .color {
      .plus-icon {
        display: block;
      }
    }
  }
}
</style>
