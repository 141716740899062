<template>
  <div
    v-if="enabledAppearanceGroups.length"
    class="product-settings-appearance-groups"
  >
    <ToggleButton
      v-slot="{ item }"
      :items="enabledAppearanceGroups"
      :item-key="(appearanceGroup) => appearanceGroup.id"
      :item-selected="(item, value) => item.id === value.id"
      :value="currentAppearanceGroup"
      inline
      @change="selectAppearanceGroup"
    >
      <div class="appearance-group">
        <ColorCircle :color="item.uiColor" :size="24" />
        <span>{{ $t(item.name) }}</span>
      </div>
    </ToggleButton>
    <div
      v-if="disabledAppearanceGroups.length"
      class="disabled-appearance-groups"
    >
      <strong>
        {{ $t('PUBLISHING.DETAIL.PRODUCTS.DEACTIVATED_COLORS.HEADING') }}
        <SmartFaqLink
          :link="smartFaqLinks.HELP_CENTER.key"
          icon-only
          class="help-link"
      /></strong>
      <p>
        {{ $t('PUBLISHING.DETAIL.PRODUCTS.DEACTIVATED_COLORS.SUBHEADING') }}
      </p>
      <ToggleButton
        v-slot="{ item }"
        class="appearance-groups"
        :items="disabledAppearanceGroups"
        :item-key="(appearanceGroup) => appearanceGroup.id"
        disabled
        inline
      >
        <div class="appearance-group">
          <ColorCircle :color="item.uiColor" :size="24" deactivated />
          <span>{{ item.name }}</span>
        </div>
      </ToggleButton>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import compositionCategoryHelper from '@/composition/compositionCategoryHelper';
import compositionHelper from '@/composition/compositionHelper';
import { useStore } from 'vuex';
import ToggleButton from 'src/app/commons/ToggleButton/ToggleButton.vue';
import ColorCircle from 'src/app/commons/colorCircle/ColorCircle.vue';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';

const store = useStore();

const emit = defineEmits(['onSelect']);
const props = defineProps({
  composition: {
    type: Object,
    required: true,
  },
  selectedAppearanceGroup: {
    type: String,
  },
});

const automaticAppearanceGroup = {
  name: 'PUBLISHING.DETAIL.PRODUCTS.DEFAULT_COLOR.AUTOMATIC',
};

const appearanceGroups = computed(() =>
  compositionCategoryHelper.getAllAvailableAppearanceGroups(
    store.state.publishingCore.categories,
  ),
);
const enabledAppearanceGroups = computed(() => [
  automaticAppearanceGroup,
  ...appearanceGroups.value.filter(
    (appearanceGroup) =>
      !compositionHelper
        .getDisabledAppearanceGroupIds(props.composition)
        .includes(appearanceGroup.id),
  ),
]);

const currentAppearanceGroup = computed(
  () =>
    enabledAppearanceGroups.value.find(
      (ag) => ag.id === props.selectedAppearanceGroup,
    ) ||
    compositionHelper.getDefaultAppearanceGroup(props.composition) ||
    automaticAppearanceGroup,
);
const disabledAppearanceGroups = computed(() =>
  appearanceGroups.value.filter((appearanceGroup) =>
    compositionHelper
      .getDisabledAppearanceGroupIds(props.composition)
      .includes(appearanceGroup.id),
  ),
);

const selectAppearanceGroup = (appearanceGroup) => {
  emit('onSelect', appearanceGroup.id);
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';

.appearance-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  min-width: 56px;

  :deep(.color) {
    margin: 0;
  }

  span {
    text-align: center;
  }
}

.disabled-appearance-groups {
  margin-top: 24px;

  & > strong {
    display: flex;
    gap: 8px;
  }

  & > p {
    margin: 8px 0 16px 0;
  }
}
</style>
