<template>
  <div class="publishing-upload-list">
    <div class="header">
      <h1>{{ $t('PUBLISHING.UPLOAD.HEADING') }}</h1>
      <p>{{ $t('PUBLISHING.UPLOAD.SUBHEADING') }}</p>
    </div>
    <div class="upload-content">
      <transition-group name="fade">
        <PublishingUploadItem
          v-for="uploadItem in unsuccessfullUploadItems"
          :key="uploadItem.id"
          :file="uploadItem.file"
          class="item"
          @on-done="() => onUploadItemDone(uploadItem.id)"
        />
      </transition-group>
      <transition name="fade">
        <h4 v-if="allSuccessfull" class="success-msg">
          <Icon icon="accept" />
          {{ $t('PUBLISHING.UPLOAD.SUCCESS') }}
        </h4>
      </transition>
    </div>
  </div>
</template>

<script>
import { delay } from '@/utils';
import PublishingUploadItem from './PublishingUploadItem.vue';

export default {
  name: 'PublishingUploadList',
  components: { PublishingUploadItem },
  props: {
    files: { type: Array, required: true },
  },
  emits: ['onDone'],
  data() {
    return {
      uploadItems: [],
      allSuccessfull: false,
    };
  },
  computed: {
    unsuccessfullUploadItems() {
      return this.uploadItems.filter((uploadItem) => !uploadItem.success);
    },
    allUploadItemsDone() {
      return this.uploadItems.every(
        (uploadItem) => uploadItem.success || uploadItem.error,
      );
    },
  },
  watch: {
    allUploadItemsDone: {
      deep: true,
      async handler(val) {
        if (val) {
          const successCount = this.uploadItems.find(
            (uploadItem) => uploadItem.success,
          );
          const errorCount = this.uploadItems.find(
            (uploadItem) => uploadItem.error,
          );
          await delay(1000);
          if (!errorCount) {
            this.allSuccessfull = true;
          }
          await delay(2000);
          this.$emit('onDone', { successCount, errorCount });
        }
      },
    },
  },
  async created() {
    this.files.forEach((file, index) => {
      this.uploadItems.push({
        id: index,
        file,
      });
    });
  },
  methods: {
    onUploadItemDone(uploadItemId) {
      const uploadItem = this.uploadItems.find(
        (item) => item.id === uploadItemId,
      );
      uploadItem.success = true;
    },
    onUploadItemFailed(uploadItem) {
      uploadItem.error = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.publishing-upload-list {
  .header {
    text-align: center;
    margin: 0 0 16px 0;

    h1 {
      margin: 0 0 16px 0;
    }

    p {
      margin: 0;
      color: $grey60;
    }
  }

  .item {
    &:not(:last-child) {
      margin: 0 0 16px 0;
    }
  }

  .upload-content {
    min-height: 48px;
  }

  .success-msg {
    color: $pa-color-green;
    font-weight: bold;
    margin: 0;
    padding: 8px 0;
    display: flex;
    align-items: center;
    display: block;
    text-align: center;

    .icon {
      margin-right: 8px;
    }
  }
}
</style>
