<template>
  <div class="dialog-size-md publishing-create-dialog">
    <div class="modal-body">
      <div v-if="currentMode === MODES.SELECTION">
        <div class="dialog-title">
          <h1>{{ $t('PUBLISHING.CREATE.HEADING') }}</h1>
        </div>
        <p class="subheading">
          {{ $t('PUBLISHING.CREATE.SUBHEADING') }}
        </p>

        <div
          class="upload-section design-upload-area"
          :class="addDynamicUploadStyling"
          @dragover.prevent="dragging = true"
          @dragleave.prevent="dragging = false"
          @dragend.prevent="dragging = false"
          @drop.prevent="onFileDrop"
        >
          <h1>{{ $t('PUBLISHING.CREATE.UPLOAD.HEADING') }}</h1>
          <p class="subheading">
            {{ $t('DESIGNS.UPLOAD.FILE_TYPES') }} .jpeg, .png, .gif, .svg, .ai,
            .cdr
          </p>
          <button
            class="btn icon-btn sprd-btn-primary"
            type="submit"
            @click="() => $refs.hiddenFileInput.click()"
          >
            <Icon icon="upload" />
            {{ $t('PUBLISHING.CREATE.UPLOAD.CTA') }}
          </button>
          <input
            ref="hiddenFileInput"
            type="file"
            multiple
            class="hidden-file-input"
            @change="uploadFiles"
          />
        </div>
        <div class="text-sm copyright-hint">
          <small v-html="$t('DESIGNS.UPLOAD.LIST.COPYRIGHT_LABEL')"></small>
        </div>
        <!-- Uncomment when the ai is enabled for the new journey
        <div class="ai-container-wrapper" v-if="aiEnabled">
          <AiContainer scaleOnHover :borderRadius="12">
            <Banner
              no-icon
              heading="AI_DESIGN_GENERATOR.UPLOAD_BANNER.HEADING"
              subheading="AI_DESIGN_GENERATOR.UPLOAD_BANNER.SUBHEADING"
              clickable
              link-icon="ai-feature"
              link="Create with AI"
              @onLinkClick="openAiGenerator"
            />
          </AiContainer>
        </div>
        -->
        <div class="section-row">
          <div class="box-section">
            <GraphicsServiceBanner
              condensed
              link-icon="outside"
              class="graphics-service"
            />
          </div>
          <div v-if="isShopScope" class="box-section">
            <EmbroideryServiceBanner
              condensed
              link-icon="outside"
              class="embroidery-service"
            />
          </div>
        </div>
      </div>
      <PublishingUploadList
        v-if="currentMode === MODES.UPLOAD"
        :files="files"
        @on-done="onCreationDone"
      />
      <PublishingCreateFromDesign
        v-if="currentMode === MODES.SELECT_DESIGN"
        @on-done="onCreationDone"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PublishingCreateFromDesign from './PublishingCreateFromDesign.vue';
import PublishingUploadList from './PublishingUploadList.vue';
import GraphicsServiceBanner from 'src/app/components/banners/GraphicsServiceBanner.vue';
import EmbroideryServiceBanner from 'src/app/components/banners/EmbroideryServiceBanner.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import AiDesignGeneratorDialog from '@/designGenerator/AiDesignGeneratorDialog.vue';
import analytics from '@/tracking/analytics';

export default {
  name: 'PublishingCreateDialog',
  components: {
    PublishingUploadList,
    PublishingCreateFromDesign,
    GraphicsServiceBanner,
    EmbroideryServiceBanner,
  },
  props: {
    modalInstance: { type: Object, required: true },
    data: { type: Object },
  },
  data() {
    return {
      files: null,
      MODES: {
        SELECTION: 'SELECTION',
        UPLOAD: 'UPLOAD',
        SELECT_DESIGN: 'SELECT_DESIGN',
      },
      currentMode: null,
      dragging: false,
    };
  },
  computed: {
    ...mapGetters({
      isShopScope: 'publishing/isShopScope',
      aiEnabled: 'settings/aiEnabled',
    }),
    addDynamicUploadStyling() {
      return {
        dragover: this.dragging,
      };
    },
  },
  async created() {
    this.currentMode = this.MODES.SELECTION;
  },
  methods: {
    uploadFiles(evt) {
      this.files = [...evt.target.files];
      this.currentMode = this.MODES.UPLOAD;
    },
    onCreationDone({ errorCount } = {}) {
      if (!errorCount) {
        this.modalInstance.close();
      }
    },
    onFileDrop(evt) {
      this.files = evt.dataTransfer
        ? evt.dataTransfer.files
        : evt.srcElement.files;
      this.currentMode = this.MODES.UPLOAD;
      this.dragging = false;
    },
    async openAiGenerator() {
      // will be used when the new journey has ai features
      try {
        await dialogService.openDialog(AiDesignGeneratorDialog, null, {
          staticBackdrop: true,
          preventEsc: true,
        });
      } catch (_) {
        analytics.logEvent('ai_generator_skip');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/links';

h1 {
  margin: 0 0 12px 0;
}

.subheading {
  color: $grey60;
  margin: 0;
}

.section-row {
  display: flex;
  gap: 24px;

  & > .box-section {
    flex: 1;
  }
}

.upload-section {
  margin: 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  height: 320px;
  position: relative;
  border-radius: $border-radius-large;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23BDBFC0FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");

  .icon {
    width: 24px;
    height: 24px;
  }

  & > h1 {
    margin: 0;
  }

  &.dragover {
    background-color: $grey15;
    overflow: hidden;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%233434ECFF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  }
}

.box-section {
  border: 1px solid $grey15;
  border-radius: $border-radius-medium;
  display: block;
  padding: 16px;
  margin: 24px 0 0 0;
  position: relative;

  button {
    margin: 24px 0 0 0;
  }
}

.ai-container-wrapper {
  margin: 24px 0 8px 0;
  padding-top: 24px;
}

.upload-icon-circle {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $grey5;

  .icon {
    width: 24px;
    height: 24px;
  }
}

h3 {
  margin: 0 0 8px 0;
}

.copyright-hint {
  margin: 10px 0;
  color: $grey60;
}

:deep(.copyright-hint) {
  a {
    @extend .link-main;
  }
}

.graphics-service,
.embroidery-service {
  margin: 0;
}
</style>
