<template>
  <div class="showroom">
    <div class="header">
      <h2>
        {{ $t('POS.MARKETPLACE.SHOWROOM_SETTINGS') }}
      </h2>
      <div class="actions">
        <a
          :href="showroomUrl"
          target="_blank"
          class="btn text-btn icon-btn link-main"
        >
          <Icon icon="globe" />
          {{ $t('POS.VISIT_SHOWROOM') }}
        </a>
        <button
          v-if="partnerType === 'BOTH'"
          class="btn text-btn icon-btn link-default-red"
          @click="openDialog"
        >
          <Icon icon="bin" />
          {{ $t('GENERAL.DELETE') }}
        </button>
      </div>
    </div>
    <iframe
      v-if="showroomUrl"
      :src="`${showroomUrl}?srEdit=pa`"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import localeService from '@/localeService/localeService';
import dialogService from '@/dialogs/wrapper/dialogService';
import ConfirmDialog from '@/dialogs/confirm/ConfirmDialog.vue';
import UnpublishFromMp from './UnpublishFromMp.vue';
import analyticsService from '@/tracking/analytics';

export default {
  name: 'Showroom',
  computed: {
    ...mapState({
      userId: (s) => s.user.data.id,
      meta: (s) => s.ideas.meta,
    }),
    ...mapGetters({
      partnerType: 'user/partnerType',
    }),
    ideaCount() {
      return this.meta?.count || 0;
    },
    showroomUrl() {
      return `${localeService.getSprdUrl()}/shop/user/${this.userId}`;
    },
  },
  async created() {
    const options = {
      posFilter: 'MARKETPLACE',
      limit: 1,
    };
    await this.fetchIdeas(options);
  },
  methods: {
    ...mapActions({
      setSetting: 'settings/setSetting',
      fetchIdeas: 'ideas/fetchIdeas',
    }),
    ...mapMutations({
      addToast: 'toasts/addToast',
    }),
    openDialog() {
      analyticsService.logEvent('remove_marketplace_header_click');

      if (this.ideaCount > 0) return this.openUnpublishDialog();

      this.openDeletionDialog();
    },
    async openUnpublishDialog() {
      try {
        await dialogService.openDialog(ConfirmDialog, {
          heading: 'POS.MARKETPLACE.DELETION.UNPUBLISH_HEADING',
          content: UnpublishFromMp,
          confirm: 'POS.MARKETPLACE.DELETION.CONFIRM_REDIRECT',
        });

        analyticsService.logEvent('remove_marketplace_go_to_filtered_list');

        this.$router.push({
          name: 'partnerarea.ideas',
          query: { posFilter: 'MARKETPLACE' },
        });
      } catch (_) {
        // modal dismissed
      }
    },
    async openDeletionDialog() {
      try {
        await dialogService.openDialog(ConfirmDialog, {
          heading: 'POS.MARKETPLACE.DELETION.HEADING',
          description: 'POS.MARKETPLACE.DELETION.DESCRIPTION',
        });

        analyticsService.logEvent('remove_marketplace_delete');

        this.setSetting({ key: 'MP_ENABLED', value: false });

        this.$router.push({ name: 'partnerarea.dashboard' });

        this.addToast({
          variant: 'black',
          text: 'POS.MARKETPLACE.DELETION.TOAST',
          hideIcon: true,
        });
      } catch (_) {
        // modal dismissed
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.showroom {
  display: flex;
  flex-direction: column;

  .header {
    height: 80px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    padding-inline: 24px;
    margin-bottom: 2px;
    background-color: #fff;
    justify-content: space-between;

    h2 {
      margin: 0;
    }

    .actions {
      display: flex;

      & > * {
        margin-left: 16px;
      }
    }
  }

  iframe {
    flex-grow: 1;
  }
}
</style>
