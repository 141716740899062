import publishingCoreService from '@/api/publishingCoreService/publishingCoreService';
import { PUBLISHING_SCOPE } from '@/publishing/publishingScope';

let resolveOnDataLoaded;

export default {
  namespaced: true,
  state: {
    config: null,
    translations: null,
    categories: null,
    appearanceGroups: null,
    shops: null,
    loaded: false,
    onDataLoaded: new Promise((resolve) => {
      resolveOnDataLoaded = resolve;
    }),
    intentsForScope: {
      [PUBLISHING_SCOPE.MP.key]: false,
      [PUBLISHING_SCOPE.SHOP.key]: false,
    },
  },
  getters: {
    registeredUserShops: (state, getters, rootState) =>
      rootState.user.shops.filter((userShop) =>
        state.shops.some((shop) => shop.shopId === userShop.id),
      ),
    hasRegisteredShops: (state) => state.shops && !!state.shops.length,
    hasMpIntent: (state) => state.intentsForScope[PUBLISHING_SCOPE.MP.key],
    hasShopIntent: (state) => state.intentsForScope[PUBLISHING_SCOPE.SHOP.key],
  },
  mutations: {
    setConfig(state, config) {
      state.config = config;
    },
    setTranslations(state, translations) {
      state.translations = translations;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    setAppearanceGroups(state, appearanceGroups) {
      state.appearanceGroups = appearanceGroups;
    },
    setShops(state, shops) {
      state.shops = shops;
    },
    setLoaded(state) {
      state.loaded = true;
    },
    setHasIntentsForScope(state, intents) {
      state.intentsForScope = intents;
    },
  },
  actions: {
    async fetchData({ dispatch, commit }) {
      await Promise.all([
        dispatch('fetchConfig'),
        dispatch('fetchTranslations'),
        dispatch('fetchCategories'),
        dispatch('fetchAppearanceGroups'),
        dispatch('fetchShops'),
      ]);
      resolveOnDataLoaded();
      commit('setLoaded');
    },
    async fetchConfig({ commit, state }) {
      if (state.config) {
        return Promise.resolve();
      }
      const data = await publishingCoreService.getConfig();
      commit('setConfig', data);
    },
    async fetchAppearanceGroups({ commit, state }) {
      if (state.appearanceGroups) {
        return Promise.resolve();
      }
      const data = await publishingCoreService.getAppearanceGroups();
      commit('setAppearanceGroups', data);
    },
    async fetchTranslations({ commit, state }) {
      if (state.config) {
        return Promise.resolve();
      }
      const data = await publishingCoreService.getTranslations();
      commit('setTranslations', data);
    },
    async fetchCategories({ commit, state }) {
      if (state.config) {
        return Promise.resolve();
      }
      const data = await publishingCoreService.getCategories();
      commit('setCategories', data);
    },
    async fetchShops({ commit, state }) {
      if (state.shops) {
        return Promise.resolve();
      }
      const data = await publishingCoreService.getShops();
      commit('setShops', data);
    },
    fetchPublishingIntents: async ({ commit }) => {
      const counts = await Promise.all([
        publishingCoreService.getPosStateCounts({
          scope: PUBLISHING_SCOPE.MP.key,
        }),
        publishingCoreService.getPosStateCounts({
          scope: PUBLISHING_SCOPE.SHOP.key,
        }),
      ]);
      delete counts[0].stateCounts.UNPUBLISHED;
      delete counts[1].stateCounts.UNPUBLISHED;
      const intents = {
        [PUBLISHING_SCOPE.MP.key]: Object.values(counts[0].stateCounts).some(
          (count) => count > 0
        ),
        [PUBLISHING_SCOPE.SHOP.key]: Object.values(counts[1].stateCounts).some(
          (count) => count > 0
        ),
      };
      commit('setHasIntentsForScope', intents);
    },
  },
};
