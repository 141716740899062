import localeService from '@/localeService/localeService';
import store from '@/store/Store';

export default {
  getAllProductTypes(category) {
    if (category.children?.length) {
      return [
        ...new Set(
          category.children.reduce(
            (result, current) =>
              result
                .concat(category.productTypes)
                .concat(this.getAllProductTypes(current)),
            [],
          ),
        ),
      ];
    } else {
      return [...new Set([...category.productTypes])];
    }
  },
  getAllProductTypeIds(category) {
    return this.getAllProductTypes(category)?.map((pt) => pt.id) || [];
  },
  getAllCategoryIds(category) {
    if (category.children?.length) {
      return [
        ...new Set(
          category.children.reduce(
            (result, current) => result.concat(this.getAllCategoryIds(current)),
            [category.id],
          ),
        ),
      ];
    } else {
      return [...new Set([category.id])];
    }
  },
  getTopLevelCategoriesWithProductTypeIds(categories) {
    return categories.children.map((topLevelCat) => ({
      id: topLevelCat.id,
      name: topLevelCat.names[localeService.getLocale()],
      productTypeIds: this.getAllProductTypeIds(topLevelCat),
    }));
  },
  isCategoryInsideCategoryLevel(categories, category) {
    return this.getAllCategoryIds(categories).includes(category.id);
  },
  getAllAvailableHotspots(category) {
    const productTypes = this.getAllProductTypes(category);

    const hotspots = [
      ...new Set(
        productTypes.reduce(
          (prev, current) => [...prev, ...current.hotspots],
          [],
        ),
      ),
    ];
    return hotspots;
  },
  getAllAvailableAppearanceGroups(category) {
    const productTypes = this.getAllProductTypes(category);

    const appearanceGroups = [
      ...new Set(
        productTypes.reduce(
          (prev, current) => [...prev, ...current.appearanceGroups],
          [],
        ),
      ),
    ];

    const groups = store.state.publishingCore.appearanceGroups;
    const appearanceGroupTranslations =
      store.state.publishingCore.translations.appearanceGroups;

    return appearanceGroups.map((appearanceGroupId) => ({
      ...groups.find((group) => group.id === appearanceGroupId),
      name: appearanceGroupTranslations[appearanceGroupId].name,
    }));
  },
};
