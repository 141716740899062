import { deepCopy } from '@/utils';
import publishingCoreService from '@/api/publishingCoreService/publishingCoreService';
import compositionHelper from '@/composition/compositionHelper';
import { createInitialPosStateCounts } from '@/api/models/publishingCore/compositionPosStates';

const defaultState = {
  list: [],
  originalList: [],
  meta: {
    count: null,
    limit: null,
    offset: null,
  },
  filter: {
    query: null,
    posState: null,
    page: 0,
    pageSize: 20,
  },
  posStateCounts: createInitialPosStateCounts(),
  searchDeliveredResults: false,
};

export default {
  namespaced: true,
  state: deepCopy(defaultState),
  getters: {
    totalCompositionCount: (state) => state.meta.count,
    unsuccessfulSearch: (state) =>
      (state.filter.query || state.filter.posState) &&
      !state.searchDeliveredResults,
    searched: (state) => state.filter.query || state.filter.posState,
  },
  mutations: {
    reset(state) {
      Object.assign(state, defaultState);
    },
    setList(state, { compositions, meta }) {
      state.list = compositions.map(
        compositionHelper.addEmptyDataStructureForDefaultMetaData,
      );
      state.meta = meta;
      state.originalList = deepCopy(state.list);
    },
    replaceComposition(state, composition) {
      const index = state.list.findIndex((i) => i.id === composition.id);
      state.list[index] = deepCopy(composition);
    },
    updateOriginal(state) {
      state.originalList = deepCopy(state.list);
    },
    resetToOriginal(state) {
      state.list = deepCopy(state.originalList);
    },
    setFilter(state, filter) {
      Object.assign(state.filter, filter);
    },
    resetFilter(state) {
      state.filter = deepCopy(defaultState.filter);
    },
    setSearchDeliveredResults(state, hasResults) {
      state.searchDeliveredResults = hasResults;
    },
    setPosStateCounts(state, posStateCounts) {
      Object.assign(state.posStateCounts, posStateCounts);
    },
  },
  actions: {
    fetchCompositions: async ({
      state,
      commit,
      dispatch,
      getters,
      rootState,
    }) => {
      const { query, pageSize, page, posState } = state.filter;
      const scope = rootState.publishing.currentScope?.key;

      // Fetch compositions based on the current filter
      const response = await publishingCoreService.getCompositions({
        query: query || null,
        limit: pageSize,
        offset: pageSize * page,
        scope,
        posState,
      });

      let { list: compositions, count, limit, offset } = response;

      if (getters.searched) {
        const hasResults = compositions?.length > 0;
        commit('setSearchDeliveredResults', hasResults);

        // If no results for query, fallback to default compositions
        if (!hasResults) {
          const defaultResponse = await publishingCoreService.getCompositions({
            limit: pageSize,
            offset: 0,
            scope,
          });

          compositions = defaultResponse.list;
          count = defaultResponse.count;
          limit = defaultResponse.limit;
          offset = defaultResponse.offset;
        }
      }

      commit('setList', { compositions, meta: { count, limit, offset } });
      if (page === 0) {
        await dispatch('fetchPosStateCounts');
      }
    },
    replaceComposition: ({ commit }, composition) => {
      commit('replaceComposition', composition);
      commit('updateOriginal');
    },
    deleteComposition: async ({ commit, dispatch, state }, compositionId) => {
      await publishingCoreService.deleteComposition(compositionId);
      if (state.list.length <= 1) {
        commit('setFilter', { page: 0 });
      }
      await dispatch('fetchCompositions');
      dispatch('publishingCore/fetchPublishingIntents', null, { root: true });
    },
    fetchPosStateCounts: async ({ state, commit, rootState }) => {
      const { query } = state.filter;
      const counts = await publishingCoreService.getPosStateCounts({
        scope: rootState.publishing.currentScope?.key,
        query,
      });
      commit('setPosStateCounts', counts);
    },
  },
};
